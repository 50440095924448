// 接口名定义
export interface Apis {
  // 查询设备列表
  selectDeviceByList: string;
  // 新增设备
  insertDevice: string;
  // 导入设备
  importDevice: string;
  // 批量分配
  batchAllocateDevice: string;
  // 批量回收设备
  batchRecycle: string;
  // 查询设备详情
  selectDeviceByDeviceId: string;
  // 设备名称修改
  updateDeviceName: string;
  // 设备删除
  deleteDevice: string;
  // 分页查询回收设备列表
  selectDeviceRecycleByList: string;
  // 设备还原
  recycleDevice: string;
  // 分页查询设备列表
  selectDeviceByPage: string;
  // 获取设备类型列表
  getDeviceTypeList: string;
  getOperationRecords: string;
  getHistoryByTypeId: (typeId: string) => string;
  getDeviceConfig: string;
  setDeviceConfig: string;
  updateDeviceSelective: string;
  getSpecies: string;
  // 获取肥力传感器配置
  getFertilityConfig: string;
  // 修改肥力传感器配置
  updateFertilityConfig: string;
  // 获取垃圾桶配置
  selectTrashcanConfigById: string;
  // 新增垃圾桶配置
  insertTrashcanConfig: string;
  // 修改垃圾桶配置
  updateTrashcanConfig: string;
  // 修改监控设备配置
  insertUpdateCamera: string;
  /** 查询设备网络信息列表 */
  selectDeviceNetworkByList: string;
  /**  查询设备网络信息详情 */
  selectDeviceNetworkById: string;
  /**  修改设备网络信息 */
  updateDeviceNetwork: string;
  /**  新增设备网络信息 */
  insertDeviceNetwork: string;
  /**  删除设备网络信息 */
  deleteDeviceNetwork: string;
  /** 获取倾斜设备信息 */
  selectDumpConfById: string;
  /** 保存倾斜设备信息 */
  insertDumpConf: string;
  /** 查询井盖配置详情 */
  selectManholeConfById: string;
  /** 保存井盖配置 */
  insertManholeConf: string;
  /** 新增水尺配置详情 */
  insertDraftConfig: string;
  /** 查询水尺配置详情 */
  selectDraftConfigById: string;
  /** 查询虫情配置详情 */
  selectInsectConfigByList: string;
  /** 更新虫情配置详情 */
  insertUpdateAllInsectConfig: string;
}
const baseUrl = '/iparkAdmin/'

export const apis: Apis = {
  selectDeviceByList: baseUrl + 'device/selectDeviceByList',
  insertDevice: baseUrl + 'device/insertDevice',
  importDevice: baseUrl + 'device/importDevice',
  batchAllocateDevice: baseUrl + 'device/batchAllocateDevice',
  batchRecycle: baseUrl + 'device/batchRecycle',
  selectDeviceByDeviceId: baseUrl + 'device/selectDeviceByDeviceId',
  updateDeviceName: baseUrl + 'device/updateDeviceName',
  deleteDevice: baseUrl + 'device/deleteDevice',
  selectDeviceRecycleByList: baseUrl + 'device/selectDeviceRecycleByList',
  recycleDevice: baseUrl + 'device/recycleDevice',
  selectDeviceByPage: baseUrl + 'device/selectDeviceByPage',
  getDeviceTypeList: baseUrl + 'deviceType/selectDeviceTypeByList',
  getOperationRecords: baseUrl + '/deviceAllot/selectDeviceAllotByPage',
  getDeviceConfig: '/iparkAdmin/soilConfig/selectSoilConfigByDeviceId',
  setDeviceConfig: '/iparkAdmin/soilConfig/insertUpdateSoilConfig',
  updateDeviceSelective: '/iparkAdmin/device/updateDeviceSelective',
  getSpecies: '/iparkAdmin/soilConfig/getSpeciesList',

  getHistoryByTypeId: typeId => {
    const type = new Map<string, string>()
    type.set('1001', '/device/soil/selectSoilByPage')
    type.set('1002', '/iparkAdmin/camera/selectCameraByDeviceId')
    type.set('1009', '/device/face/selectFaceByPage')
    type.set('1008', '/device/location/selectLocationByPage')
    type.set('1010', '/device/smoke/selectSmokeByPage')
    type.set('1011', '/device/trashcan/selectTrashcanByPage')
    type.set('1003', '/device/vehicle/selectVehicleByPage')
    type.set('1006', '/device/watch/selectWatchByPage')
    type.set('1004', '/device/water/selectWaterByPage')
    type.set('1012', '/device/weather/selectWeatherByPage')
    type.set('1007', '/device/valve/selectValveByPage')
    type.set('1013', '/device/fertility/selectFertilityByPage')
    type.set('1014', '/device/device/getReportData')
    type.set('1015', '/device/device/getReportData')
    type.set('1017', '/device/insect/selectInsectInfoPage')
    type.set('1019', '/device/sumec/selectSumecHisByPage')
    type.set('1018', '/device/jingXing/selectJingXingByPage')
    type.set('1020', '/device/footpathInfo/selectFootpathInfoByList')
    type.set('1021', '/device/dumpInfo/selectDumpInfoByList')
    type.set('1022', '/device/manholeData/selectManholeDataByPage')
    type.set('1023', '/device/workCard/selectWorkCardByPage')
    type.set('1024', '/business/draft/selectDraftHistoryByPage')
    type.set('1025', '/device/control/selectControlByPage')
    type.set('1026', '/device/fireFightingM/selectFireFightingMByPage')
    type.set('1027', '/device/fireFightingB/selectFireFightingBByPage')
    type.set('1028', '/device/topsailPressure/selectTopsailPressureByPage')
    type.set('1030', '/business/gas/selectGasDataByPage')
    type.set('1031', '/device/smartToilet/selectSmartToiletDataByPage')
    type.set('1033', '/device/controlcabinet/selectControlCabinetByPage')
    type.set('1032', '/device/spray/selectSprayByPage')
    return type.get(typeId) || ''
  },
  // 获取土壤肥力设备配置
  getFertilityConfig: 'iparkAdmin/fertilityConfig/selectFertilityConfigByDeviceId',
  updateFertilityConfig: 'iparkAdmin/fertilityConfig/insertUpdateFertilityConfig',
  // 垃圾桶设备配置
  selectTrashcanConfigById: '/iparkAdmin/trashcan/selectTrashcanConfigById',
  insertTrashcanConfig: '/iparkAdmin/trashcan/insertTrashcanConfig',
  updateTrashcanConfig: '/iparkAdmin/trashcan/updateTrashcanConfig',
  insertUpdateCamera: '/iparkAdmin/camera/insertUpdateCamera',
  selectDeviceNetworkByList: '/iparkAdmin/deviceNetwork/selectDeviceNetworkByList',
  selectDeviceNetworkById: '/iparkAdmin/deviceNetwork/selectDeviceNetworkById',
  updateDeviceNetwork: '/iparkAdmin/deviceNetwork/updateDeviceNetwork',
  insertDeviceNetwork: '/iparkAdmin/deviceNetwork/insertDeviceNetwork',
  deleteDeviceNetwork: '/iparkAdmin/deviceNetwork/deleteDeviceNetwork',
  selectDumpConfById: '/iparkAdmin/dumpConf/selectDumpConfById',
  insertDumpConf: '/iparkAdmin/dumpConf/insertDumpConf',
  insertManholeConf: '/iparkAdmin/manholeConf/insertManholeConf',
  selectManholeConfById: '/iparkAdmin/manholeConf/selectManholeConfById',
  // 积水监测设备配置
  insertDraftConfig: '/iparkAdmin/draftConfig/insertDraftConfig',
  selectDraftConfigById: '/iparkAdmin/draftConfig/selectDraftConfigById',
  selectInsectConfigByList: '/iparkAdmin/insectConfig/selectInsectConfigByList',
  insertUpdateAllInsectConfig: '/iparkAdmin/insectConfig/insertUpdateAllInsectConfig'
}
